<template>

  <ClientOnly>

    <Teleport to="#context">

      <div class="ctx" @click="activeContext = true">
        <i class="icon-dots"></i>
      </div>
        
      <UiModal
        v-if="activeContext"
        :title="''"
        :close-button-name="'Close'"
        :is-transparent="true"
        :is-auto-height="true"
        :width="'70%'"
        :max-width="'400px'"
        @close="activeContext = false"         
        >        
        
        <div class="context_list">
  
            <div 
              class="actions" 
              >    

              <UiButton
                :name="$t('universal_button_follow')"
                type="float"
                v-if="!props.isFollow"
                @click.native="follow" 
                />
  
              <UiButton
                :name="$t('universal_button_unfollow')"
                type="float"
                v-if="props.isFollow"
                @click.native="unfollow" 
                />

            </div>
  
        </div>
      </UiModal>
    </Teleport>    
  </ClientOnly>

</template>


<script setup>
 
import { useClipboard } from '@vueuse/core'

const { text: copyText, copy, copied: copyCopied, isSupported: copyIsSupported } = useClipboard()

const emits = defineEmits(['follow', 'unfollow']);
const props = defineProps({
  isFollow: {
    type: Boolean,
    default: false,
  }, 
})

var activeContext = ref(false)


const follow = () => {

  if(!useAuth().isAuth())
    return useAuth().goToLogin();
  
  activeContext = false; 
  emits('follow')
}
const unfollow = () => {

  if(!useAuth().isAuth())
    return useAuth().goToLogin();
  
  activeContext = false; 
  emits('unfollow')
}

</script>

<style scoped>
  .ctx{        
    min-width: 59px;
    display: flex;
    justify-content: center;
  }
  .ctx > i{    
    padding: 14px 20px 14px 25px;
    cursor: pointer;
  }

  /*  */

  .context_list{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    flex-direction: column;
  }
 
</style>
