<template>   

  <div class="pbf">

    <div class="pb grid_width">
      <div class="bre skl"></div>
    </div>

    <div class="hd skl">

      <div class="pb grid_width">
        <div class="lg skl"></div>
      </div>

    </div>

    <div class="pb grid_width">
      <div class="inf">
        <div class="pho skl"></div>
        <div class="desc">
          <div class="desc_rw skl"></div>
          <div class="desc_rw skl"></div>
          <div class="desc_rw skl"></div>
        </div>
      </div>
    </div>

    <!-- products -->

    <div class="pb grid_width">
      <div class="ttl skl"></div>    
      <div class="ls">
        <div class="lspr skl"></div>    
        <div class="lspr skl"></div>    
        <div class="lspr skl"></div>    
        <div class="lspr skl"></div>    
        <div class="lspr skl"></div>    
        <div class="lspr skl"></div>    
      </div>    
    </div>    




  </div>

</template>

 
  

<style scoped>

.hd{
  margin-top: 20px;
  height: 121px;
  overflow: inherit;
}
.hd .lg{
  width: 110px;
  height: auto;
  aspect-ratio: 1/1;
  border-radius: 100px;
  margin-top: -58px;
  background-color: #f2f2f2;
  box-shadow: 0px 0px 10px #00000012;
}

.bre{
  width: 100px;
  height: 20px;
  margin-left: auto;
}

.inf{
  display: flex;
  align-items: flex-start;
    gap: 2rem;
}
.inf .pho{
  width: 250px;
  aspect-ratio: 1/0.9;
  flex-shrink: 0;
}
.inf .desc{
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
.inf .desc .desc_rw{
  width: 100%;
  height: 25px;
}
.inf .desc .desc_rw:last-child{
  max-width: 80%;
}

@container pb (max-width: 600px) {
  .inf{
    flex-direction: column;
  }
  .inf .pho{
    width: 100%; 
  }
}

/* products */

.ttl{
  width: 140px;
  height: 20px;
  margin-bottom: 1rem;
}
.ls{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 1rem;
}
.ls .lspr{
  width: calc(16.66667% - 20px);
  aspect-ratio: 1/1.5;
}



@container pb (max-width: 600px) {
  .ls .lspr{
    width: calc(50% - 20px);
  }  
}


</style>
